import React from 'react'
import Layout from '../components/Layout'

class ITunesStoreLandingPage extends React.Component {
  componentDidMount() {
    const ua = navigator.userAgent
    if (!ua.match('Facebot') && !ua.match('facebookexternalhit')) {
      window.location =
        'https://itunes.apple.com/us/app/david-bowie-is/id1447594485'
    }
  }

  render() {
    return (
      <div className="landing" style={{ display: 'none' }}>
        David Bowie is
      </div>
    )
  }
}

export default ITunesStoreLandingPage
