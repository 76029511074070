import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import './Header.css'

import appStoreButton from '../images/itunes-store-badge.png'
import playStoreButton from '../images/google-play-badge.png'

const Header = ({ siteTitle }) => (
  <div className="Header">
    <a
      href="https://itunes.apple.com/us/app/david-bowie-is/id1447594485"
      className="Header_app-store-link"
    >
      <img src={appStoreButton} />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.planeta.BowieMobile"
      className="Header_play-store-link"
    >
      <img src={playStoreButton} />
    </a>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
