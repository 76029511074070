import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header'
import { TranslationProvider } from './Translation'
import renderHTML from 'react-render-html'

import './fonts.css'
import './Layout.css'

const Layout = ({ language, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        allTranslations {
          edges {
            node {
              en {
                site_title
                top_title
                top_col1_content
                top_col2_content
                bottom_title
                bottom_col1_content
                bottom_col2_content
                bottom_col3_content
                mailing_list_cta
                mailing_list_email_placeholder
                mailing_list_subscribe
                site_terms_label
                site_terms_url
                app_terms_label
                app_terms_url
              }
              ja {
                site_title
                top_title
                top_col1_content
                top_col2_content
                bottom_title
                bottom_col1_content
                bottom_col2_content
                bottom_col3_content
                mailing_list_cta
                mailing_list_email_placeholder
                mailing_list_subscribe
                site_terms_label
                site_terms_url
                app_terms_label
                app_terms_url
              }
            }
          }
        }
      }
    `}
    render={(data, b) => {
      const translations = data.allTranslations.edges[0].node
      const activeTranslation = translations[language]
      const t = key => {
        const str = activeTranslation[key]
        if (!str) return ''
        else return renderHTML(str)
      }
      return (
        <TranslationProvider value={{ t, translations: activeTranslation }}>
          <Header siteTitle={t('site_title')} />
          {children}
        </TranslationProvider>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
