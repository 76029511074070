import React from 'react'

export const TranslationContext = React.createContext({ en: {}, jp: {} })
export const TranslationProvider = TranslationContext.Provider
export const TranslationConsumer = TranslationContext.Consumer

export const translate = key => (
  <TranslationContext.Consumer>
    {value => value[key]}
  </TranslationContext.Consumer>
)
